<template>
  <div class="table-container">
    <b-table
      :data="devices"
      :loading="loading"
      striped
      hoverable
      bordered
      paginated
      backend-pagination
      pagination-position="both"
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      detailed
      detail-key="id"
    >
      <b-table-column field="index" label="Index" sortable v-slot="props">
        {{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}
      </b-table-column>

      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'device', params: { id: props.row.id } }">
          {{ props.row.id }}
        </router-link>
      </b-table-column>

      <b-table-column field="name" label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="label" label="Label" sortable v-slot="props">
        <router-link :to="{ name: 'messages', params: { deviceId: props.row.id }, query: { page: 1 } }">
          {{ props.row.label }}
        </router-link>
      </b-table-column>

      <b-table-column field="last_seen" label="Last seen" sortable v-slot="props">
        {{ props.row.last_seen | moment('YYYY-MM-DD HH:mm:ss') }}
      </b-table-column>

      <b-table-column field="enabled" label="Enabled" sortable v-slot="props">
        {{ props.row.enabled ? 'yes' : 'no' }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" v-slot="props">
        <router-link :to="{ name: 'device', params: { id: props.row.id } }" class="mr-1">
          <b-button size="is-small" title="Detail">
            <b-icon icon="eye" size="is-small" />
          </b-button>
        </router-link>
        <router-link :to="{ name: 'device-transfer', params: { id: props.row.id } }">
          <b-button size="is-small" title="Device transfer">
            <b-icon icon="folder-swap-outline" size="is-small" />
          </b-button>
        </router-link>
      </b-table-column>

      <b-table-column field="link" label="" v-slot="props">
        <router-link :to="{ name: 'messages', params: { deviceId: props.row.id }, query: { page: 1 } }">
          Messages
        </router-link>
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nothing here.</p>
          </div>
        </section>
      </template>

      <template slot="detail" slot-scope="props">
        <device-statistics :device_id="props.row.id" :cache="props.row" />
      </template>

      <template slot="top-left">
        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined :loading="loading">REFRESH</b-button>

        <b-button v-on:click="exportMessagesCSV" type="is-info" icon-left="download" outlined class="ml-3"
          >EXPORT MESSAGES</b-button
        >
      </template>

      <template slot="bottom-left">
        <b-button tag="router-link" v-if="isAdmin" :to="{ name: 'device-add' }" type="is-success" icon-left="plus-box"
          >Add Device</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import Device from '../models/Device';
import DeviceStatistics from '../components/DeviceStatistics.vue';
import ExportDialog from '../components/ExportDialog.vue';

export default {
  name: 'devices',
  components: { DeviceStatistics },
  data() {
    return {
      devices: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      this.offset = (this.page - 1) * this.perPage;
      const res = await Device.getAll(this.$route.params.groupId, this.sortField, this.sortOrder, limit, this.offset);
      this.devices = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    exportMessagesCSV() {
      const props = {
        since: this.since,
        before: this.before,
        groupId: this.$route.params.groupId,
        sortField: 'created_at',
        sortOrder: 'desc',
      };
      this.$buefy.modal.open({
        parent: this,
        component: ExportDialog,
        props,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
      });
    },
  },
};
</script>
